import { breakpoints } from 'src/styles/breakpoints'
import styled from 'styled-components'

export const CardContainer = styled.div`
  background: white;
  padding: 16px 16px;
  align-items: center;
  border-radius: 16px;
  min-height: 132px;

  p {
    margin-left: 16px;
  }

  circle {
    stroke-width: 2.5;
  }

  rect {
    stroke-width: 2.5;
  }

  path {
    stroke-width: 2.5;
  }
`
