import React from 'react'
import Img from 'gatsby-image'
import usePageQuery from '../../pageQuery'
import useWidth from 'src/hooks/window/useWidth'
import { ComoContratarSection, StepNumber } from './style'
import * as URLS from 'config/api/Urls'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

const DataWithStep = [
  {
    position: '1',
    title: 'Confira se sua empresa é conveniada',
    description: 'Verifique com o RH da sua empresa se ela é conveniada ao Consignado Privado Inter. ',
  },
  {
    position: '2',
    title: 'Faça uma simulação',
    description: 'Na calculadora, informe o valor total que você precisa ou o valor que deseja pagar nas parcelas mensais, insira seus dados, o nome da sua empresa e finalize, enviando a proposta.',
  },
  {
    position: '3',
    title: 'Avaliação da solicitação',
    description: 'Pronto! Sua solicitação será avaliada, e você receberá o seu contrato para assinar e nos enviar de volta, tudo por nossa conta!',
  },
  {
    position: '4',
    title: 'Liberação do empréstimo',
    description: 'Após recebermos todos os documentos necessários e aprovarmos a sua análise, o empréstimo será liberado em sua conta rapidamente!',
  },
]

type dataProps = {
  position: string;
  title: string;
  description: string;
}

const ComoContratar = () => {
  const data = usePageQuery()
  const width = useWidth()
  const WIDTH_MD = 720
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <ComoContratarSection className='bg-white d-flex position-relative align-items-center pt-5'>
      <div className='container'>
        <div className='row align-items-center justify-content-between'>
          <div className='col-12 col-md-6'>
            {width > WIDTH_MD ? (
              <Img
                className='my-0 mx-auto'
                fluid={data.comoContratarDesktop.fluid}
                alt='Tela do superapp Inter com passo a passo de contratação do Consignado Privado' style={{ maxWidth: '500px' }}
              />
        ) : (
          <Img
            className='my-0 mx-auto'
            fluid={data.comoContratarMobile.fluid}
            alt='Tela do superapp Inter com passo a passo de contratação do Consignado Privado' style={{ maxWidth: '250px' }}
          />
          )}
          </div>

          <div className='d-flex flex-column col-sm-6 col-xl-5'>
            <h2 className='fs-32 lh-40 fs-md-24 lh-30 fs-lg-40 lh-lg-50 font-sora text-grayscale--500 fw-600 mt-4 pb-2'>
              Como contratar o Consignado para empresas?
            </h2>
            <p className='fs-18 lh-22 fs-md-16 lh-19 fs-lg-18 lh-lg-22 text-gray--700 fw-400 pb-3'>Você pode contratar o seu Empréstimo Consignado para assalariado pelo site ou pelo app do Inter em apenas alguns cliques! Veja como é simples:</p>

            {DataWithStep.map((item: dataProps) => (
              <div key={item.position} className='d-flex align-items-start pb-3 mb-3'>
                <StepNumber className='fs-14 lh-14 text-white'>{item.position}</StepNumber>
                <div>
                  <p className='text-grayscale--400 fs-14 fw-700 lh-17 mb-2 px-0'>{item.title}</p>
                  <p className='text-grayscale--400 fs-14 lh-17 fw-400 mb-0' dangerouslySetInnerHTML={{ __html: item.description }} />
                </div>
              </div>
            ))}

            <a
              href={`${URLS.API_CONSIGNADO_PRIVADO}/onboarding`}
              target='blank'
              className='btn text-white btn-orange--extra text-none mb-5'
              onClick={() => {
                sendDatalayerEvent({
                  section: 'dobra_04',
                  element_action: 'click button',
                  element_name: 'Quero contratar!',
                  section_name: 'Como contratar o Consignado para empresas?',
                  redirect_url: `${URLS.API_CONSIGNADO_PRIVADO}/onboarding`,
                })
              }}
            >
              Quero contratar!
            </a>
          </div>
        </div>
      </div>
    </ComoContratarSection>
  )
}

export default ComoContratar
