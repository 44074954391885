import React from 'react'
import { Section, Title, Description, Simulator } from './style'

import BreadCrumb from 'src/components/Breadcrumb'
import PayrollSimulatorNew from 'src/components/PayrollSimulator/PayrollSimulatorNew'

const Hero = () => {
  return (
    <Section id='private-payroll-header' className='pb-5 pt-md-5 d-md-flex position-relative align-items-md-center justify-content-md-center'>
      <div className='container'>
        <div className='row align-items-center justify-content-between'>
          <div className='col-12 col-md-6 col-lg-7 col-xl-6 pr-md-0'>
            <div className='d-none d-md-block mb-md-3 pl-lg-1'>
              <BreadCrumb
                sectionName='Consignado Privado simples e rápido para você!'
                type='Pra você'
                text='Empréstimo consignado privado'
                link='/pra-voce/emprestimos/emprestimo-consignado-privado/'
              />
            </div>
            <Title>Consignado Privado simples e rápido para você!</Title>
            <Description>Consignado Privado Inter é <strong>dinheiro rápido</strong> com as <strong>melhores taxas</strong> e as <strong>menores parcelas</strong> para funcionários de carteira assinada. Simule seu empréstimo para assalariado agora!</Description>
          </div>
          <Simulator className='col-12 col-md-6 col-lg-5'>
            <PayrollSimulatorNew
              page='empresa'
              borderColor='#F5F6FA'
              sectionName='Consignado Privado simples e rápido para você!'
              sectionNumber='dobra_1'
            />
          </Simulator>
        </div>
      </div>
    </Section>
  )
}

export default Hero
