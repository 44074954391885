import { useStaticQuery, graphql } from 'gatsby'

const PageQuery = () => {
  const pageQuery = useStaticQuery(graphql`
    query {
      comoContratarDesktop: imageSharp(fluid: {originalName: { regex: "/como-contratar-desktop/" }}) {
        fluid(maxWidth: 900, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      comoContratarMobile: imageSharp(fluid: {originalName: { regex: "/como-contratar-mobile/" }}) {
        fluid(maxWidth: 487, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      quaisVantagensMulher: imageSharp(fluid: {originalName: { regex: "/quais-vantagens-mulher/" }}) {
        fluid(maxWidth: 310, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      quaisVantagensHomem: imageSharp(fluid: {originalName: { regex: "/quais-vantagens-homem/" }}) {
        fluid(maxWidth: 310, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  `)
  return pageQuery
}

export default PageQuery
