import { device } from 'src/styles/breakpoints'
import styled from 'styled-components'

export const TabSection = styled.section`

  .h-panel {
    min-height: 540px;

    @media ${device.tablet} {
      min-height: 368px;
    }

    @media ${device.desktopLG} {
      min-height: 489px;
    }

    @media ${device.desktopXL} {
      min-height: 613px;
    }

    .invest {
      margin-bottom: 30px;

      @media ${device.tablet} {
        margin-bottom: 100px;
      }

      @media ${device.desktopXXL} {
        margin-bottom: 250px;
      }
    }
  }
  .positionHr {
    position: relative;
    top: 41px;
  }

  .react-multi-carousel-dot {
    margin: 0;
  }

  .tab-conta-digital-pj {

    ul {
      justify-content: start !important;
      list-style: none;
      display: flex;
      flex-wrap: no-wrap;
      overflow-y: hidden;
      @media ${device.desktopXL} {
        overflow-y: unset;
      }
      height: 34px;

      li {
        flex: 0 0 auto;
        width: auto;
        max-width: 100%;
        margin-right: 32px;
        line-height: 17px;
        margin-left: 0;

        button {
          padding-bottom: 0px;

          &:first-child {
            padding-left: 0;
          }
        }
      }
    }
  }

  button {

    @media ${device.desktopLG} {
      max-width: 376px;
    }
    @media ${device.desktopXL} {
      max-width: 456px;
    }
  }

  @media ${device.desktopLG} {
    .gatsby-image-wrapper {
      max-width: 456px;
    }
  }
`
