import React from 'react'

import Layout from 'src/layouts/BaseLayout'

import pageContext from './pageContext.json'

import Hero from './sections/header/_index'
import FAQ from './sections/Faq/_index'
import ComoContratar from './sections/ComoContratar/_index'
import ComoUsar from './sections/ComoUsar/_index'
import QuaisVantagens from './sections/QuaisVantagens/_index'

function EmprestimoConsignadoPrivado () {
  return (
    <Layout pageContext={pageContext}>
      <Hero />
      <QuaisVantagens />
      <ComoUsar />
      <ComoContratar />
      <FAQ faq={pageContext.structuredData.faq} />
    </Layout>
  )
}

export default EmprestimoConsignadoPrivado
