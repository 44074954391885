import React from 'react'
import Img from 'gatsby-image'
import OrangeDSIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'
import TabSectionJSON from '../../assets/data/TabSection.json'
import usePageQuery from '../../pageQuery'
import Tab from 'src/components/Tab'
import { orange } from 'src/styles/colors'
import { TabSection } from './style'

type QuaisVantagensProps = {
  icon: string;
  title: string;
  description: string;
}

type ITabSection = {
  id: number;
  title: string;
  description: string;
  image: string;
  alt: string;
  advantages: QuaisVantagensProps[];
}

const QuaisVantagens = () => {
  const data = usePageQuery()

  return (
    <section className='pb-4 pt-5'>
      <TabSection>
        <div className='container'>
          <Tab
            items={[ 'Vantagens para você', 'Vantagens para sua empresa' ]}
            styles='fs-14 lh-17 fw-700 mb-3'
            classDiv='tab-conta-digital-pj'
            color='#161616'
          >
            {
              TabSectionJSON.map((item: ITabSection) => (
                <div className='pt-md-4' key={item.id}>
                  <div className='row d-flex justify-content-between align-items-center'>
                    <div className='col-12 col-md-6 col-lg-5 mb-5 mb-md-0'>
                      <Img fluid={data[item.image].fluid} alt={item.alt} />
                    </div>
                    <div className='col-12 col-md-6'>
                      <h2 className='fs-24 lh-30 fs-lg-32 lh-lg-40 fw-600 font-sora text-grayscale--500 mb-4'>{item.title}</h2>
                      <p className='fs-16 lh-19 fs-lg-18 lh-lg-22 fw-400 text-grayscale--400 mb-4'>{item.description}</p>
                      {item.advantages.map((advantage: QuaisVantagensProps) => (
                        <div className='d-flex mb-4' key={advantage.icon}>
                          <div className='mr-3'>
                            <OrangeDSIcon icon={advantage.icon} color={orange.extra} size='MD' />
                          </div>
                          <div>
                            <h3 className='fs-16 lh-20 text-grayscale--500 font-sora fw-600 mb-2'>{advantage.title}</h3>
                            <p className='fs-14 lh-17 fw-400 text-grayscale--400 mb-3'>{advantage.description}</p>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              ))
            }
          </Tab>
        </div>
      </TabSection>
    </section>
  )
}

export default QuaisVantagens
