import React from 'react'
import OrangeIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'
import { orange } from 'src/styles/colors'
import { CardContainer } from './style'

import ComoUsarJson from '../../assets/data/ComoUsar.json'

interface IAdvantagesTypes {
  icon: string;
  text: string;
  title: string;
}

const ComoUsar = () => {
  return (
    <section className='bg-orange--extra py-5'>
      <div className='container my-lg-5'>
        <h2 className='fs-24 lh-30 fs-md-32 lh-md-40 fs-lg-40 lh-lg-50 fw-600 text-white mb-3 text-md-center font-sora'>
          Como usar o Consignado Privado?
        </h2>
        <p className='fs-16 lh-19 fs-lg-18 lh-lg-22 text-white mb-4 text-md-center'>O empréstimo para assalariado Inter é para você usar como quiser e precisar!</p>

        <div className='row'>
          {ComoUsarJson.map((advantages: IAdvantagesTypes) => (
            <div className='col-12 col-sm-6 col-xl-4 mt-4' key={advantages.icon}>
              <CardContainer>
                <div className='d-flex'>
                  <OrangeIcon icon={advantages.icon} size='MD' color={orange.extra} />
                  <p className='fs-20 lh-25 fs-md-18 lh-md-23 fs-lg-20 lh-lg-25 fw-600 font-sora text-grayscale--500 mb-3' dangerouslySetInnerHTML={{ __html: advantages.title }} />
                </div>
                <span className='fs-16 lh-19 fs-md-16 lh-md-20 fs-lg-18 lh-lg-22 text-grayscale--500' dangerouslySetInnerHTML={{ __html: advantages.text }} />
              </CardContainer>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default ComoUsar
